<template>
  <div class="patientTerminal">
    <van-form
      class="login-form"
      validate-first
      @submit="success"
      :show-error-message="false"
      validate-trigger="onChange"
    >
      <van-field
        v-model="name"
        label="姓名"
        readonly
        placeholder="请输入姓名"
      />
      <van-field
        v-model="parentSignature"
        label="家长姓名"
        placeholder="请输入家长姓名"
      />
      <van-field
        v-model="idNo"
        label="身份证"
        maxlength="18"
        readonly
        placeholder="请输入身份证号"
      />
      <van-field
        v-model="phone"
        readonly
        label="手机号"
        maxlength="11"
        placeholder="请输入手机号"
      />
      <van-field name="checkboxGroup" label="既往病史">
        <template #input>
          <van-checkbox-group v-model="medicalHistory" direction="horizontal">
            <van-checkbox name="先天性心脏病">先天性心脏病</van-checkbox>
            <van-checkbox name="癫痫">癫痫</van-checkbox>
            <van-checkbox name="高热惊厥">高热惊厥</van-checkbox>
            <van-checkbox name="哮喘">哮喘</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field
        v-model="otherMedicalHistory"
        label="其他病史"
        placeholder="请输入其他既往病史"
      />
      <van-field
        v-model="allergyHistory"
        label="过敏史"
        placeholder="请输入过敏史"
      />
      <van-field
        v-model="parentConfirmSignature"
        label="家长确认签名"
        placeholder="家长确认并签名"
      />
      <van-field
        v-model="kindergartenName"
        label="幼儿园名称"
        placeholder="请输入幼儿园名称"
      />
      <van-field
        v-model="address"
        label="地址"
        :rules="[{ required: true, message: '请输入地址' }]"
        placeholder="请输入地址"
      />
      <van-field
        readonly
        clickable
        label="合约单位编码"
        :value="conComid"
        placeholder="请选择合约单位"
        @click="conComidPicker = true"
      />
      <van-popup v-model="conComidPicker" position="bottom">
        <van-picker
          show-toolbar
          value-key="con_comname"
          :columns="conComidcolumns"
          @confirm="conComidConfirm"
          @cancel="conComidPicker = false"
        />
      </van-popup>
      <!-- 登录 -->
      <div class="submit" style="margin: 16px;">
        <van-button
          class="buttonColor"
          round
          block
          type="info"
          native-type="submit"
        >
          {{ isCreatedCard ? '缴费' : '建卡' }}
        </van-button>
      </div>
    </van-form>
    <div class="medId" v-if="healthNo">
      <!-- 二维码 -->
      <vue-qr :text="healthNo" :size="200" bindElement></vue-qr>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
import { insertStr, getNow } from '../../../common/utils'
import { createHealthCard, constractUnitInfo } from '@/network/service'
import { mapState } from 'vuex'
// import nationList from './nationList'
export default {
  data() {
    return {
      name: '',
      idNo: '',
      phone: '',
      conComid: '',
      conComidPicker: false,
      conComidcolumns: [],
      isCreatedCard: false,
      healthNo: '',
      address: '',
      allergyHistory: '',
      parentSignature: '',
      kindergartenName: '',
      medicalHistory: [],
      otherMedicalHistory: '',
      parentConfirmSignature: '',
    }
  },
  components: {
    vueQr,
  },
  computed: {
    ...mapState(['cardList']),
  },
  mounted() {
    let { name, idNo, phone } = this.$store.state.cardList[0]
    this.name = name
    this.idNo = this.$Base64.decode(idNo)
    this.phone = this.$Base64.decode(phone)
    this.getconstractUnitInfo()
  },
  methods: {
    conComidConfirm(val) {
      this.conComid = val['con_comid']
      this.conComidPicker = false
    },
    async getconstractUnitInfo() {
      let res = await constractUnitInfo()
      if (res.code == 0) {
        this.conComidcolumns = res.data.root.body.detail
      } else {
        this.$toast(res.msg)
      }
    },
    success() {
      if (this.isCreatedCard) {
        this.$router.push({
          path: '/physicalPay',
          query: { healthNo: this.healthNo },
        })
      } else {
        this.createCard()
      }
    },
    async createCard() {
      let res = await createHealthCard({
        name: this.name,
        sex: this.idNo.slice(16, 17) % 2 == 0 ? '2' : '1', //1男2女
        birthday: insertStr(insertStr(this.idNo.slice(6, 14), 4, '-'), 7, '-'),
        id_no: this.idNo,
        phone: this.phone,
        address: this.address,
        patient_type: '自费',
        con_comid: this.conComid,
        operate_time: getNow(),
        operate_no: 'WXGZH',
        parentname: this.parentSignature,
        jiwangs: this.medicalHistory.join('|'),
        guomins: this.allergyHistory,
        kindergarten: this.kindergartenName,
        Jiwangs_qt: this.otherMedicalHistory,
        parentsign: this.parentConfirmSignature,
      })
      if (res.code == 0) {
        this.isCreatedCard = true
        this.healthNo = res.data.root.body.card_no
      } else {
        this.$toast(res.msg)
      }
    },
  },
  watch: {
    name: function() {
      this.isCreatedCard = false
      this.healthNo = ''
    },
    idNo: function() {
      this.isCreatedCard = false
      this.healthNo = ''
    },
    phone: function() {
      this.isCreatedCard = false
      this.healthNo = ''
    },
    conComid: function() {
      this.isCreatedCard = false
      this.healthNo = ''
    },
  },
}
</script>

<style lang="less" scoped>
.patientTerminal {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  .buttonColor {
    background: @darkColor;
    border-color: @darkColor;
  }
  .medId {
    width: 200px;
    margin: auto;
  }
}

.van-checkbox--horizontal {
  margin-bottom: 0.3rem;
}
</style>
